// Components
import { aclService } from '@emobg/access-utils';
import { DELAY, getValue, navigationErrorHandler } from '@emobg/web-utils';
import debounce from 'lodash/debounce';
import find from 'lodash/find';

import MyBookingsRules from '@Bookings/legacy/MyBookingsRules';

import Geocomplete from '@/vue/components/molecules/common/MapSearchBox/Geocomplete';
import GoogleMaps from '@Shared/components/Map/Map';
import LocationDetailsModal from '@/vue/components/organism/common/LocationDetails/LocationDetailsModal';
import EventHandlerMixin from '@/mixins/EventHandler';
import AuthenticatedUserMixin from '@/mixins/AuthenticatedUser';
import LoaderMixin from '@/mixins/Loader';
import ProfileMixin from '@/mixins/Profile';
import CompanyDataMixin from '@/mixins/Company';
import SegmentMixin from '@/mixins/Segment';
import googleMapsLoader from '@/vue/managers/GoogleMapsLoader';
import { geolocationManager } from '@/vue/managers/GeolocationManager';
import { MapManager, ZOOM_LEVEL_TO_VIEW_MARKER } from '@/vue/managers/MapManager';
import { useAuth } from '@/composable/Api/Auth/auth';
import { SEGMENT_EVENTS } from '@/vue/constants';
import { INTERVENTION_BOOKING_TYPE, REGULAR_BOOKING_TYPE } from '@/constants/permissions';
import { genericErrorArgs } from '@/constants/defaultModalArgs';
import { fetchCities } from '@/stores/City/CityMapper';

import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import { useUserData } from '@/composable/User/useUserData';

import Location from '../../components/Location/Location';
import LocationPlaceholder from '../../components/LocationPlaceholder/LocationPlaceholder';
import Datetimepicker from '../../components/Datetimepicker/Datetimepicker';
import BookingRoutesNames from '../../router/routes-names';
import { usePagination } from '../composable/usePagination';

export default {
  props: {
    authenticated: {
      type: Boolean,
      default: false,
    },
    auth: {
      type: Object,
      default: () => ({}),
    },
    isIntervention: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mapManager: new MapManager(),
      display: {
        mapLoaded: false,
      },
      cancellationFees: {},
      bookingUuidSelected: '',
      datetimepickerManager: new MyBookingsRules(),
      routes: {
        myBookings: {
          ...BookingRoutesNames,
        },
      },
      arrCsOperatorsUuid: [],
      myBookingsLogbook: [],
    };
  },

  mixins: [
    AuthenticatedUserMixin,
    EventHandlerMixin,
    LoaderMixin,
    ProfileMixin,
    CompanyDataMixin,
    SegmentMixin,
  ],

  components: {
    GoogleMaps,
    Location,
    LocationPlaceholder,
    Geocomplete,
    LocationDetailsModal,
    Datetimepicker,
  },

  computed: {
    // TODO: [SBCSB-2306] Replace for getter MyActiveBookingsUsers + Vehicle
    activeAndRequestBookings() {
      return this.$store.getters['Booking/activeAndRequestBookingsMapped'];
    },
    routeNames() {
      if (this.hasOnlyInterventionType) {
        return {
          active: this.routes.myBookings.myInterventionsActive,
          history: this.routes.myBookings.myInterventionsHistory,
          logbook: null,
        };
      }

      return {
        active: this.routes.myBookings.myBookingsActive,
        history: this.routes.myBookings.myBookingsHistory,
        logbook: this.routes.myBookings.myBookingsLogbook,
      };
    },
  },

  // TODO: [SBCSB-2306] Move to MyBookingsUsers
  setup() {
    const { logout } = useAuth();
    const { notifyError } = useNotifications();

    const { loadActiveBookings } = usePagination();

    const {
      userData,
      setUserGeolocation,
    } = useUserData();

    return {
      logout,
      notifyError,
      loadActiveBookings,
      userData,
      setUserGeolocation,
    };
  },

  async created() {
    // TODO: [SBCSB-2306] Move to MyBookingsUsers
    try {
      await this.checkBookingTypesPermissions();

      this.setLoaderStatus(false);

      const currentProfileUuid = getValue(this, 'currentProfile.uuid', '');
      if (currentProfileUuid) {
        const cities = await this.fetchCities({ userProfileUuid: currentProfileUuid });

        const defaultCity = find(cities, { uuid: getValue(this, 'userData.defaultCity.uuid', '') });
        if (defaultCity) {
          geolocationManager.setLatitudeAndLongitude(
            defaultCity.gps_lat,
            defaultCity.gps_lng,
          );
        }
      }

      await googleMapsLoader.createInstance();

      // Get browser geolocation
      const geolocation = await geolocationManager.getNavigatorGeolocation();

      this.setUserGeolocation(geolocation);

      this.fetchCSOperator(this.getUserData().getCarsharingOperatorUUID())
        .then(operator => {
          this.setVisitedCsOperator(operator);
        });

      this.eventHandler.$on(this.events.MAP_LOADED, this.onMapLoaded);
      this.display.mapLoaded = true;
    } catch (_error) {
      this.eventHandler.$emit(this.events.SHOW_ERROR_MODAL_PAGE, {
        ...genericErrorArgs(this.$t),
        error: this.$t('notifications.whooops'),
      });
      this.setLoaderStatus(false);
    }

    // TODO: [SBCSB-2306] Move to MyBookingsUsers including methods
    this.eventHandler.$on(this.events.UPDATE_ACTIVE_BOOKINGS, this.updateActiveBookings);

    this.trackSegment({
      name: SEGMENT_EVENTS.MY_BOOKINGS,
    });
  },

  // TODO: Check if it's not need anymore after refactor ALL my bookings
  beforeDestroy() {
    this.eventHandler.$off(this.events.MAP_LOADED, this.onMapLoaded);
    this.eventHandler.$off(this.events.UPDATE_ACTIVE_BOOKINGS, this.updateActiveBookings);
    this.eventHandler.$off(this.events.MAP_BOUNCING, this.debouncedMapBouncing);
  },

  methods: {
    fetchCities,

    // TODO: [SBCSB-2306] Move to MyBookingsUsers
    goTo(routeName) {
      this.$router.push({ name: routeName }).catch(navigationErrorHandler);
    },

    // TODO: [SBCSB-2306] Move to MyBookingsUsers
    isRoute(routeName) {
      return this.$route.name === routeName;
    },

    // TODO: [SBCSB-2306] Move to MyBookingsUsers
    async onChangeInputGeocomplete({ lat, lng }) {
      if (!(lat && lng) || !this.mapManager || (this.mapManager && !this.mapManager.getMap())) {
        return;
      }

      try {
        this.mapManager.setCenterAndZoomMap({ lat, lng }, ZOOM_LEVEL_TO_VIEW_MARKER);
      } catch (error) {
        this.notifyError({
          text: error.message,
        });
      }
    },

    initMapFlow() {
      if (!this.mapManager || (this.mapManager && !this.mapManager.getMap())) {
        return;
      }

      this.mapManager.setCenterAndZoomMap(
        {
          lat: this.getUserGeolocation().getLatitude(),
          lng: this.getUserGeolocation().getLongitude(),
        },
        ZOOM_LEVEL_TO_VIEW_MARKER,
      );

      this.loadActiveBookings();
    },

    onMapLoaded() {
      const currentPosition = getValue(this, '$refs.geocompleteInput.$refs.currentPosition', undefined);
      if (currentPosition) {
        if (this.getUserGeolocation().isActive()) {
          currentPosition.active();
        } else {
          currentPosition.deactivate();
        }
      }

      this.initMapFlow();

      this.setLoaderStatus(false);

      this.eventHandler.$on(this.events.MAP_BOUNCING, this.debouncedMapBouncing);
    },

    debouncedMapBouncing: debounce(function debounceOnMapBouncing() {
      this.onMapBouncing();
    }, DELAY.medium),

    onMapBouncing() {
      if (!this.mapManager || (this.mapManager && !this.mapManager.getMap())) {
        return;
      }

      if (geolocationManager.isActive()) {
        const mapLat = this.mapManager.getMapCenterLatitude();
        const mapLong = this.mapManager.getMapCenterLongitude();

        const isClose = geolocationManager.isCloseOfMyPosition(mapLat, mapLong);
        const currentPosition = getValue(this, '$refs.geocompleteInput.$refs.currentPosition', undefined);
        if (currentPosition) {
          if (isClose) {
            currentPosition.active();
          } else {
            currentPosition.deactivate();
          }
        }
      }
    },

    // TODO: [SBCSB-2306] Move to MyBookingsUsers
    async onChangeProfile() {
      await this.checkBookingTypesPermissions();

      this.initMapFlow();
    },

    // TODO: [SBCSB-2306] Refactor this on upgrade insurance
    async updateActiveBookings() {
      await this.loadActiveBookings();
      this.goTo(this.routeNames.active);
    },

    // TODO: [SBCSB-2306] Move to MyBookingsUsers and refactor
    checkBookingTypesPermissions() {
      return new Promise(resolve => {
        const canRegularBookings = aclService.hasUserPermissions(REGULAR_BOOKING_TYPE);
        const canInterventionBookings = aclService.hasUserPermissions(INTERVENTION_BOOKING_TYPE);

        if (!canRegularBookings && !canInterventionBookings) {
          this.logout();
          return;
        }

        if (this.hasOnlyInterventionType && !canInterventionBookings) {
          this.$router.push({ name: BookingRoutesNames.myBookings }).catch(navigationErrorHandler);
          return;
        }

        if (!this.hasOnlyInterventionType && !canRegularBookings) {
          this.$router.push({ name: BookingRoutesNames.myInterventions }).catch(navigationErrorHandler);
          return;
        }

        resolve();
      });
    },

  },
};
